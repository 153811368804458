import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="178" height="8" viewBox="0 0 178 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.093 8C29.4129 7.25735 2.46655 8.08646 0.507925 7.86265C-1.09111 7.56254 1.46428 2.27751 2.94855 1.49925C3.46881 1.21949 4.07323 1.17881 5.45804 1.30597C7.65384 1.54505 36.0462 1.07198 36.0462 1.07198C36.4211 1.23475 37.5458 0.685396 37.7524 0.883775C38.4027 1.34666 84.4227 0.715913 88.3476 0.69048C90.727 0.67522 88.8449 0.99059 91.6451 0.863424C97.6128 0.609092 110.619 0.853252 115.141 0.563314C118.76 0.324241 119.54 0.314075 123.014 0.507368C126.564 0.680314 145.438 0.431066 147.596 0.0597408C148.124 -0.0419921 148.529 -0.00638251 148.529 0.11061C148.529 0.136044 161.834 0.222513 161.895 0.314073C161.995 0.431066 162.4 0.487015 162.775 0.405629C163.471 0.283549 177.633 -0.15899 177.855 0.181815C178.551 1.15845 176.6 5.31932 174.955 6.33157C173.272 7.41502 164.03 6.19932 156.601 6.85041L122.011 6.91654L114.598 7.32347C113.748 6.97249 110.482 7.85756 108.508 7.22682C107.674 6.97757 101.782 6.94196 100.482 7.20647C99.3495 7.40994 69.2127 6.89618 66.1218 7.60323C60.6132 7.01318 38.2726 7.85757 33.093 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="108" height="8" viewBox="0 0 108 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0789 8C17.846 7.25735 1.49656 8.08646 0.308179 7.86265C-0.66202 7.56254 0.888442 2.27751 1.78901 1.49925C2.10467 1.21949 2.4714 1.17881 3.31162 1.30597C4.6439 1.54505 21.8707 1.07198 21.8707 1.07198C22.0982 1.23475 22.7806 0.685396 22.9059 0.883775C23.3005 1.34666 51.2227 0.715913 53.6041 0.69048C55.0478 0.67522 53.9059 0.99059 55.6049 0.863424C59.2257 0.609092 67.1173 0.853252 69.8608 0.563314C72.0565 0.324241 72.53 0.314075 74.6375 0.507368C76.7914 0.680314 88.2435 0.431066 89.5526 0.0597408C89.8729 -0.0419921 90.1189 -0.00638251 90.1189 0.11061C90.1189 0.136044 98.1915 0.222513 98.2287 0.314073C98.289 0.431066 98.535 0.487015 98.7625 0.405629C99.1849 0.283549 107.777 -0.15899 107.912 0.181815C108.334 1.15845 107.151 5.31932 106.153 6.33157C105.131 7.41502 99.5238 6.19932 95.0163 6.85041L74.0294 6.91654L69.5312 7.32347C69.0159 6.97249 67.0337 7.85756 65.8361 7.22682C65.3301 6.97757 61.7557 6.94196 60.9665 7.20647C60.2795 7.40994 41.9942 6.89618 40.1188 7.60323C36.7765 7.01318 23.2216 7.85757 20.0789 8Z" fill="#FFA700" />
    </svg>
  </span>
)