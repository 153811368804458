import React from "react";
import Typed from "react-typed"

const BannerNegro = () => {
    return (
        <div className="container-banner-negro-divisor">
            <p>
                Añade{" "}
                <Typed
                    strings={[
                        "IA",
                        "Llamadas",
                        "WhatsApp",
                        "Correo electrónico",
                        "Redes Sociales",
                        "SMS",
                        "Chatbots",
                        "IVR in/out",
                        "Llamadas predictivas"
                    ]}
                    typeSpeed={50}
                    backSpeed={50}
                    loop
                />
                <br /> a tu espacio de trabajo
            </p>
        </div>
    )
}
export default BannerNegro