import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="354" height="10" viewBox="0 0 354 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.8141 10C58.4953 9.07169 4.90538 10.1081 1.01014 9.82831C-2.16995 9.45317 2.91211 2.84689 5.86398 1.87407C6.89865 1.52436 8.10069 1.47351 10.8547 1.63247C15.2217 1.93131 71.6874 1.33997 71.6874 1.33997C72.433 1.54344 74.6697 0.856744 75.0805 1.10472C76.3739 1.68332 167.897 0.894891 175.702 0.8631C180.435 0.844025 176.691 1.23824 182.26 1.07928C194.129 0.761364 219.996 1.06657 228.988 0.704142C236.185 0.405302 237.737 0.392594 244.645 0.63421C251.705 0.850392 289.243 0.538832 293.533 0.074676C294.583 -0.0524901 295.39 -0.00797814 295.39 0.138263C295.39 0.170054 321.85 0.278142 321.972 0.392591C322.17 0.538832 322.976 0.608769 323.721 0.507036C325.106 0.354437 353.271 -0.198737 353.712 0.227269C355.096 1.44806 351.216 6.64915 347.945 7.91446C344.598 9.26878 326.217 7.74915 311.442 8.56302L242.652 8.64568L227.908 9.15434C226.219 8.71561 219.722 9.82196 215.796 9.03353C214.138 8.72197 202.421 8.67746 199.835 9.00809C197.583 9.26242 137.648 8.62023 131.501 9.50403C120.545 8.76647 76.1152 9.82197 65.8141 10Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
